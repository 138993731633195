import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { faArrowCircleRight } from '@fortawesome/free-solid-svg-icons';
import { BehaviorSubject, combineLatestWith, Observable, Subscription } from 'rxjs';
import { CalendarService } from '../../services/calendar/calendar.service';
import { AvailableTime } from '../../api/models/available-time';
import { StateService } from '../../services/state/state.service';
import { ColorLegendService } from '../../services/calendar/color-legend.service';
import { CalendarDateFirstService } from '../../services/calendar/date-first/calendar-date-first.service';
import { BookingService } from '../../services/booking/booking.service';
import { AppointmentService } from '../../services/appointment/appointment.service';
import { environment } from '../../../environments/environment';
import { EnvironmentsEnum } from '../../enum/environments-enum';
import { AvailableCourtTime } from '../../api/models/available-court-time';
import { AgencyTimePickInterface } from '../../components/pikaday/service-only-time-pick/AgencyTimePickInterface';
import { AgencyService } from '../../services/agency/agency.service';
import { AvailableDetailedAgencyTime } from '../../components/pikaday/service-only-time-pick/AvailableDetailedAgencyTime';
import { Agency } from '../../api/models/agency';
import { ApplicationSettingsService } from '../../services/application-settings/application-settings.service';
import { DatePickService } from './service/date-pick.service';
import { SessionStorageService } from '../../services/session-storage/session-storage.service';

@Component({
    selector: 'otb-date-pick',
    templateUrl: './date-pick.component.html',
    styleUrls: ['./date-pick.component.scss', './date-pick.component.mags.scss']
})
export class DatePickComponent implements AfterViewInit, OnDestroy, OnInit {
    @ViewChild('calendarContainer') calendarWrapper: ElementRef<HTMLDivElement>;

    faArrowCircleRight = faArrowCircleRight;
    availableAgencyTimes: Array<AvailableCourtTime> = [];
    availableDetailedAgencyTimes: Array<AvailableDetailedAgencyTime> = [];
    subs: Subscription = new Subscription();
    calendarShow: boolean = true;
    calendarLoading: boolean = true;
    isServiceOnly: boolean = false;
    timesLoading: boolean = true;
    colorLegendShow: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

    showFormSwitcherTop = true;
    nextAppointmentAt = new Date(this.bookingService.booking.nextRelease);

    appointmentReservationLoading: BehaviorSubject<boolean>;

    constructor(
        private calendarService: CalendarService,
        private stateService: StateService,
        private colorLegendService: ColorLegendService,
        private calendarDateFirstService: CalendarDateFirstService,
        private bookingService: BookingService,
        private appointmentService: AppointmentService,
        private agencyService: AgencyService,
        private applicationSettingsService: ApplicationSettingsService,
        private datePickService: DatePickService,
        private sessionStorageService: SessionStorageService
    ) {
        this.colorLegendShow = this.colorLegendService.showLegend$;
        this.showFormSwitcherTop = this.showSwitchTop();
        this.isServiceOnly = this.applicationSettingsService.serviceOnly;

        const timesLoading$ = this.calendarDateFirstService.timesLoading.subscribe((loading: boolean) => {
            this.timesLoading = loading;
        });

        this.appointmentReservationLoading = this.appointmentService.appointmentReservationLoading;

        this.subs.add(timesLoading$);
    }

    ngAfterViewInit(): void {
        this.initCalendar();
    }

    /*showSwitchTop(): boolean {
        return ![
            EnvironmentsEnum.SH,
            EnvironmentsEnum.JUSTICE,
            EnvironmentsEnum.HMDJ,
            EnvironmentsEnum.HMDIS_INTERNAL
        ].includes(<EnvironmentsEnum>environment.deploy_environment);
    }*/

    showSwitchTop(): boolean {
        return [EnvironmentsEnum.MAGS_SOP, EnvironmentsEnum.EDUCATION_CHECK, EnvironmentsEnum.FINANCE].includes(
            <EnvironmentsEnum>environment.deploy_environment
        );
    }

    back(): void {
        this.isServiceOnly ? this.stateService.backToServiceOnly() : this.stateService.back();
    }

    ngOnInit(): void {
        this.bookingService.resetDateTime();
        this.nextAppointmentAt = new Date(this.bookingService.booking.nextRelease);

        const availableTimesSub: Subscription = this.calendarService.availableTimes
            .pipe(combineLatestWith(this.agencyService.allAgencies$))
            .subscribe(([times, agencies]) => {
                this.calendarDateFirstService.timesLoading.next(false);
                this.availableAgencyTimes = times;

                this.datePickService.timesLoaded.next(true);
                if (!times || times.length === 0) {
                    return;
                }

                // rest is for service only
                if (!this.isServiceOnly) {
                    return;
                }

                const items: AvailableDetailedAgencyTime[] = [];
                times.forEach((agencyTime) => {
                    agencyTime.times.forEach((time) => {
                        const agency = agencies.find((agency) => agency.name === agencyTime.name);
                        items.push({
                            agencyId: agency ? agency.id : 0,
                            name: agency ? <string>agency.name : '',
                            address: agency ? <string>agency.address : '',
                            time: time
                        });
                    });
                });
                this.availableDetailedAgencyTimes = this.sortTime(items);
            });

        const availableDatesSub: Subscription = this.calendarService.availableDates.subscribe((dates) => {
            this.calendarShow = dates.length > 0;
            this.calendarLoading = false;
        });

        this.subs.add(availableTimesSub);
        this.subs.add(availableDatesSub);

        if (this.isServiceOnly) {
            this.agencyService.retrieveAgencies();
        }
    }

    sortTime(items: AvailableDetailedAgencyTime[]): Array<AvailableDetailedAgencyTime> {
        return items.sort((a, b) => {
            const timeA = Date.parse('01/01/1970 ' + a.time.time);
            const timeB = Date.parse('01/01/1970 ' + b.time.time);
            if (timeA < timeB) {
                return -1;
            }
            if (timeA > timeB) {
                return 1;
            }
            return 0;
        });
    }

    private initCalendar(): void {
        const calendarContainer = this.calendarWrapper.nativeElement;
        const calendarInput = calendarContainer.getElementsByTagName('input')[0];

        try {
            this.calendarDateFirstService.initializeCalendar(calendarContainer, calendarInput);
        } catch (e) {
            this.sessionStorageService.clearWorkflowAndBooking();
            window.location.href = '/';
        }
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    onTimeSelect(time: AvailableTime): void {
        this.bookingService.booking.time = time.time;
        this.appointmentService.appointmentReservationLoading.next(true);

        if (this.bookingService.booking.time && this.bookingService.booking.date) {
            this.appointmentService.reserveAppointment();
        }
    }

    onTimeSelectServiceOnly(agencyTime: AgencyTimePickInterface): void {
        this.bookingService.booking.time = agencyTime.time.time;
        this.bookingService.booking.agency = this.findAgencyByName(agencyTime.agencyName);
        this.appointmentService.appointmentReservationLoading.next(true);
        if (this.bookingService.booking.time && this.bookingService.booking.date) {
            const workflow$ = this.callWorkflow().subscribe((success: boolean) => {
                if (success) {
                    this.appointmentService.reserveAppointment();
                }
            });

            this.subs.add(workflow$);
        }
    }

    private findAgencyByName(name: string): Agency | undefined {
        const allAgencies = this.agencyService.allAgencies$.getValue();
        return allAgencies.find((agency) => agency.name === name);
    }

    private callWorkflow(): Observable<boolean> {
        if (!this.bookingService.booking.agency) {
            throw Error('Agency is not set');
        }
        return this.stateService.updateMachine(
            this.bookingService.booking.service?.id.toString(),
            this.bookingService.booking.agency?.id.toString()
        );
    }

    switchToTimeFirst($event: Event): void {
        $event.preventDefault();
        this.calendarService.availableTimes.next([]);
        this.stateService.switchToTimeFirst();
    }

    getFirstDayInThreeMonths(date: Date): Date {
        const futureDate = new Date(date);

        futureDate.setMonth(futureDate.getMonth() + 3);

        futureDate.setDate(1);
        return futureDate;
    }
}
